<script>
  import { onMount } from "svelte";
  import { gsap } from "gsap";
  import { Router, Route, Link, router, navigateTo } from "yrv";
  import {db} from '../firebase'
  
  

  let page;
  let counter;
  let promise = getCounter();

  onMount(() => {
    //doWipe(page)
    
  });

  function navigate(route) {
    window.scrollTo(0, 0);
    navigateTo(route);
  }

  async function getCounter(){
    const counterRef = db.collection("stats").doc("--stats--")
    const data = await counterRef.get()
    .then(function(doc){
      if(doc.exists){
        console.log("Counter:", doc.data());
        counter = doc.data();
      }else{
        console.log("Couldn't get a count")
      }
    })
  }


</script>

<style>.photo{height:300px;background:#0f718f;background-image:url(/img/overhead.jpg);background-size:cover}
/*# sourceMappingURL=src/pages/Home.svelte.map */</style>

<div class="photo relative">
<div class="m-auto bg-black-trans50 py-4 absolute bottom-0 right-0 left-0">
<div class="max-w-screen-lg p-4 m-auto">
  <h1 class="text-lg text-2xl font-display text-white">Uintah Highlands Fiber Initiative</h1>
  <p class="text-white text-sm" >A community-driven effort to bring better internet to our beautiful neighborhood in unincorporated Weber County.</p>
  </div>
</div> 
</div>

<div class="max-w-screen-lg p-4 m-auto mt-4" bind:this={page}>

  <p class="text-xl leading-tight md:text-2xl font-bold ">
    Our email campaign worked!!! <br> 
    The Weber County Commissioners are listening. 
    We are now working with them to come up with a plan to get fiber installed. 
    Thank you for all the emails that were sent and for spreading the word.
  </p>

    <div class="mt-8 pt-4 md:grid md:grid-cols-2 md:gap-4">

<!-- <div class="md:col-span-1 border">
       <h2 class="bg-black text-white uppercase p-2 px-4">Email Our Commissioners </h2>
       <p class="p-4">We'll help you send an email to Weber County commissioners (Jim Harvey, Gage Froerer, and Scott Jenkins) to let them know this is important to you.</p>
    <button
      class=" m-4 self-end mt-2 block h-auto py-2 px-4 text-black text-xl font-bold shadow-md hover:shadow-lg hover:bg-blue-gray hover:text-white"
      on:click={() => {
        navigateTo('/#preview');
      }}>
      Send Email →
    </button>
    </div>
    
    <div class="md:col-span-1 mt-4 md:mt-0">
    {#await promise}
      
    {:then}
      <p class=""><span class="font-bold text-2xl">{counter.submitted}</span> <span class="text-lg">emails submitted</span></p>
      <p class="text-sm">Help us get to 100. Your voice makes a difference!</p>
    {/await}
    
    </div> -->
    <!-- Commented out petition/Notice of Interest thing for now -->
      <!-- <div class="md:col-span-1 border">
      
      <h2 class="bg-black text-white uppercase p-2 px-4"> 2. Sign Notice of Interest </h2>
      <p class="p-4">Express your interest in a fiber to the home network by adding your name to our online petition.</p>
    <button
      class="m-4 self-end mt-2 block h-auto py-2 px-4 text-black text-xl font-bold shadow-md hover:shadow-lg hover:bg-black hover:text-white"
      on:click={() => {
        navigateTo('/#petition');
      }}>
      Add Your Name →
    </button>
      
      
      </div> -->
      

    </div>
  

  <div class="mt-4 md:mt-8 p-2">
    
  

   <details class="inline-block">
    <summary class="inline-block rounded-md cursor-pointer text-xl leading-tight font-bold">Why is fiber optic to every home important?</summary>
    <div class="mt-2">
    
    <p class="mt-4">
      High speed internet has become a necessity for work, school and the
      options in our neighborhood Are simply not good enough. Internet speeds in
      our neighborhood are among the slowest in the nation, slower even than
      some developing countries.
    </p>

    <p class="mt-4">
      Many cities in Utah have installed fiber to the home solutions, successfully providing fast and reliable internet and allowing you to choose who your internet
      provider is. We'd like to bring that service to neighborhood - but we need our local politicians onboard to get this done.
    </p>

    <p class="mt-4">
      COVID-19 has made it even more clear how dependent on the internet we are for news, education, work and entertainment when everything else is shutdown.
    </p>

</div>
  </details>
  </div>

  

</div>
