<script>  
  import { Router, Route, Link, router, navigateTo } from "yrv";
  
  import {onMount} from 'svelte';
  import {gsap} from 'gsap';
  
  
  import Home from './pages/Home.svelte';  
  
  import Preview from "./pages/Preview.svelte"
  import Complete from "./pages/Complete.svelte"
  import Petition from "./pages/Petition.svelte"
  import Admin from "./pages/Admin.svelte"
  import Export from "./pages/Export.svelte"
  import Compare from "./pages/BroadbandComparison.svelte"
  import { db } from "./firebase";

  /* global USE_HASH_CHANGE */
  if (typeof USE_HASH_CHANGE !== "undefined" && USE_HASH_CHANGE) {
    Router.hashchange = USE_HASH_CHANGE;
    window.scrollTo(0);
  }

function navigate(route){
  window.scrollTo(0,0);
  navigateTo(route)
}



</script>

<style lang="text/postcss">main{display:-ms-grid;display:grid;min-height:100%;-ms-grid-rows:1fr 50px;grid-template-rows:1fr 50px}
/*# sourceMappingURL=src/App.svelte.map */</style>



<main>
  <div>

    <Router>             
        <Route exact><Home/></Route>        
        <Route exact path="/#preview"><Preview/></Route>
        <Route exact path="/#complete"><Complete/></Route>        
        <Route exact path="/#compare"><Compare/></Route>
        <Route exact path="/#admin"><Admin/></Route>
        <Route exact path="/#admin/export"><Export/></Route>
        <Route fallback><Home/></Route>
    </Router>    
  </div>

  <footer class="footer max-w-screen-xl mx-auto w-full p-4">
    <!-- <p class="text-left text-gray text-xs">Page created by Nonagon™</p>     -->
  </footer>
</main>
