<script>
import CheckIcon from '../components/CheckIcon.svelte'
import CancelIcon from '../components/CancelIcon.svelte'
import SadIcon from '../components/SadIcon.svelte'
import NeutralIcon from '../components/NeutralIcon.svelte'
import MoneyIcon from '../components/MoneyIcon.svelte'
</script>
<style>th{padding:.5rem;vertical-align:top}
/*# sourceMappingURL=src/pages/BroadbandComparison.svelte.map */</style>

<div class="max-w-screen-lg p-4 m-auto prose">
<table class="w-full table table-auto">
<thead class="table-header-group">
<tr>
  <th></th>
  <th><svg class="w-12 h-12" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="30" cy="30" r="29" stroke="black" stroke-width="2"/>
<path d="M44 56V41C44 38.7909 42.2091 37 40 37H21C18.7909 37 17 38.7909 17 41V56" stroke="black" stroke-width="2"/>
<circle cx="48.5" cy="28.5" r="2.5" stroke="black" stroke-width="2"/>
<circle r="2.5" transform="matrix(-1 0 0 1 11.5 28.5)" stroke="black" stroke-width="2"/>
<circle cx="30" cy="9" r="3" stroke="black" stroke-width="2"/>
<circle cx="42.5" cy="15.5" r="2.5" stroke="black" stroke-width="2"/>
<circle r="2.5" transform="matrix(-1 0 0 1 17.5 15.5)" stroke="black" stroke-width="2"/>
<path d="M30 12V36.5" stroke="black" stroke-width="2"/>
<path d="M46 29C40.6058 30.183 39.4773 32.6305 38 37.5" stroke="black" stroke-width="2"/>
<path d="M14 29C19.3942 30.183 20.5227 32.6305 22 37.5" stroke="black" stroke-width="2"/>
<path d="M41 17.5C35.5 21.5 34.1003 29.9196 34 37.5" stroke="black" stroke-width="2"/>
<path d="M19 17.5C24.5 21.5 25.8997 29.9196 26 37.5" stroke="black" stroke-width="2"/>
<path d="M30 13V37.5" stroke="black" stroke-width="2"/>
</svg>

  Fiber Optic</th>
  <th><svg  class="h-12 w-12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-labelledby="title"
aria-describedby="desc" role="img" xmlns:xlink="http://www.w3.org/1999/xlink">
  <title>TV</title>
  <desc>A line styled icon from Orion Icon Library.</desc>
  <path data-name="layer2"
  d="M20.7 22a12 12 0 0 1 22.6 0" fill="none" stroke="#202020" stroke-miterlimit="10"
  stroke-width="2" stroke-linejoin="round" stroke-linecap="round"></path>
  <rect data-name="layer1" x="2" y="22" width="60" height="40" rx="2"
  ry="2" fill="none" stroke="#202020" stroke-miterlimit="10" stroke-width="2"
  stroke-linejoin="round" stroke-linecap="round"></rect>
  <path data-name="layer2" fill="none" stroke="#202020" stroke-miterlimit="10"
  d="M25.8 15.8L12.1 2.1m26.1 13.7L51.9 2.1" stroke-width="2" stroke-linejoin="round"
  stroke-linecap="round"></path>
  <circle data-name="layer2" cx="54" cy="38" r="1" fill="none" stroke="#202020"
  stroke-miterlimit="10" stroke-width="2" stroke-linejoin="round" stroke-linecap="round"></circle>
  <circle data-name="layer2" cx="54" cy="45" r="1" fill="none"
  stroke="#202020" stroke-miterlimit="10" stroke-width="2" stroke-linejoin="round"
  stroke-linecap="round"></circle>
  <circle data-name="layer2" cx="54" cy="30" r="2" fill="none"
  stroke="#202020" stroke-miterlimit="10" stroke-width="2" stroke-linejoin="round"
  stroke-linecap="round"></circle>
  <rect data-name="layer1" x="6" y="26" width="40" height="29"
  rx="3" ry="3" fill="none" stroke="#202020" stroke-miterlimit="10" stroke-width="2"
  stroke-linejoin="round" stroke-linecap="round"></rect>
</svg>Cable</th>
  <th><svg class="w-12 h-12" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" aria-labelledby="title"
aria-describedby="desc" role="img" xmlns:xlink="http://www.w3.org/1999/xlink">
  <title>Dish Radio Antenna</title>
  <desc>A line styled icon from Orion Icon Library.</desc>
  <path stroke-miterlimit="10"
  stroke="#202020" fill="none" d="M52.582 44.334A26.263 26.263 0 0 1 15.441 7.192z"
  data-name="layer2" stroke-width="2" stroke-linejoin="round" stroke-linecap="round"></path>
  <path d="M27.469 51.2l5.4 10.8H5l9.689-18.451" stroke-miterlimit="10"
  stroke="#202020" fill="none" data-name="layer2" stroke-width="2" stroke-linejoin="round"
  stroke-linecap="round"></path>
  <path d="M34.984 26.735l8.735-8.734m0-8.001a8 8 0 0 1 8 8m-8-16a16 16 0 0 1 16 16"
  stroke-miterlimit="10" stroke="#202020" fill="none" data-name="layer1"
  stroke-width="2" stroke-linejoin="round" stroke-linecap="round"></path>
</svg>Wireless/5G</th>
  <th><svg class="w-12 h-12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-labelledby="title"
aria-describedby="desc" role="img" xmlns:xlink="http://www.w3.org/1999/xlink">
  <title>Satellite</title>
  <desc>A line styled icon from Orion Icon Library.</desc>
  <path data-name="layer2"
  d="M29.9 53.9a14.6 14.6 0 0 0-2.1-17.7 14.6 14.6 0 0 0-17.7-2.1z" fill="none"
  stroke="#202020" stroke-miterlimit="10" stroke-width="2" stroke-linejoin="round"
  stroke-linecap="round"></path>
  <path data-name="layer1" d="M20.4 54.1A10.5 10.5 0 0 1 9.9 43.6m-7.9.3A18.1 18.1 0 0 0 20.1 62"
  fill="none" stroke="#202020" stroke-miterlimit="10" stroke-width="2" stroke-linejoin="round"
  stroke-linecap="round"></path>
  <path data-name="layer2" d="M34.9 40.4a2 2 0 0 1-2.8 0L23.6 32a2 2 0 0 1 0-2.8l12.7-12.8a2 2 0 0 1 2.8 0l8.5 8.5a2 2 0 0 1 0 2.8zM8.067 10.748l8.486-8.485L29.28 14.99l-8.485 8.485zm32.463 32.6l8.484-8.485L61.742 47.59l-8.485 8.485zM25 19.2l4.2 4.3m11.3 11.3l4.3 4.2"
  fill="none" stroke="#202020" stroke-miterlimit="10" stroke-width="2" stroke-linejoin="round"
  stroke-linecap="round"></path>
  <path data-name="layer1" fill="none" stroke="#202020" stroke-miterlimit="10"
  d="M43.4 20.7l6.7-6.7" stroke-width="2" stroke-linejoin="round" stroke-linecap="round"></path>
</svg>
  Satellite</th>
  <th><svg class="w-12 h-12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-labelledby="title"
aria-describedby="desc" role="img" xmlns:xlink="http://www.w3.org/1999/xlink">
  <title>Landline</title>
  <desc>A line styled icon from Orion Icon Library.</desc>
  <path data-name="layer2"
  d="M52 26.3V53a6 6 0 0 1-6 6H18a6 6 0 0 1-6-6V26.3" fill="none" stroke="#202020"
  stroke-miterlimit="10" stroke-width="2" stroke-linejoin="round" stroke-linecap="round"></path>
  <rect data-name="layer2" x="20" y="30" width="4" height="4" rx="1"
  ry="1" fill="none" stroke="#202020" stroke-miterlimit="10" stroke-width="2"
  stroke-linejoin="round" stroke-linecap="round"></rect>
  <rect data-name="layer2" x="30" y="30" width="4" height="4" rx="1"
  ry="1" fill="none" stroke="#202020" stroke-miterlimit="10" stroke-width="2"
  stroke-linejoin="round" stroke-linecap="round"></rect>
  <rect data-name="layer2" x="40" y="30" width="4" height="4" rx="1"
  ry="1" fill="none" stroke="#202020" stroke-miterlimit="10" stroke-width="2"
  stroke-linejoin="round" stroke-linecap="round"></rect>
  <rect data-name="layer2" x="20" y="39" width="4" height="4" rx="1"
  ry="1" fill="none" stroke="#202020" stroke-miterlimit="10" stroke-width="2"
  stroke-linejoin="round" stroke-linecap="round"></rect>
  <rect data-name="layer2" x="30" y="39" width="4" height="4" rx="1"
  ry="1" fill="none" stroke="#202020" stroke-miterlimit="10" stroke-width="2"
  stroke-linejoin="round" stroke-linecap="round"></rect>
  <rect data-name="layer2" x="40" y="39" width="4" height="4" rx="1"
  ry="1" fill="none" stroke="#202020" stroke-miterlimit="10" stroke-width="2"
  stroke-linejoin="round" stroke-linecap="round"></rect>
  <rect data-name="layer2" x="20" y="48" width="4" height="4" rx="1"
  ry="1" fill="none" stroke="#202020" stroke-miterlimit="10" stroke-width="2"
  stroke-linejoin="round" stroke-linecap="round"></rect>
  <rect data-name="layer2" x="30" y="48" width="4" height="4" rx="1"
  ry="1" fill="none" stroke="#202020" stroke-miterlimit="10" stroke-width="2"
  stroke-linejoin="round" stroke-linecap="round"></rect>
  <rect data-name="layer2" x="40" y="48" width="4" height="4" rx="1"
  ry="1" fill="none" stroke="#202020" stroke-miterlimit="10" stroke-width="2"
  stroke-linejoin="round" stroke-linecap="round"></rect>
  <path data-name="layer1" d="M17.8 24.7a3.9 3.9 0 0 0 3-4.4c-.3-3-1.4-7.3 11.2-7.3s11.5 4.3 11.2 7.3a3.9 3.9 0 0 0 3 4.4l9.9 2c1.3.2 3.2.8 4.6-1.1a11.4 11.4 0 0 0-2-13.4C53.5 7.2 43.1 5 32 5S10.5 7.2 5.3 12.3a11.4 11.4 0 0 0-2 13.4c1.4 1.8 3.3 1.3 4.6 1.1z"
  fill="none" stroke="#202020" stroke-miterlimit="10" stroke-width="2" stroke-linejoin="round"
  stroke-linecap="round"></path>
</svg>DSL</th>
</tr>
</thead>
<tbody>
<tr class="table-row">
<th class="p-2 align-top">Technology</th>
<td><div class="flex items-center space-x-2"><CheckIcon/><span>Best</span></div>Small, flexible strands of glass transmit data as light. Fastest technology available.</td>
<td>Copper cable (coax) transmits data via electricity. Invented in 1880, used by cable TV companies.</td>
<td>Antenna on roof points to tower and transmits data through radio waves. Also delivered via mobile phone technology (4G, LTE, 5G)</td>
<td>Data transmitted to and from a satellite in space.</td>
<td>Data transmited through old copper lines originaly laid for telephone service.</td>
</tr>

<tr>
<th class="p-2 align-top">Speed Capcity</th>
<td><div class="flex items-center space-x-2"><CheckIcon/><span>Fastest</span></div> Download: Up to 100Gbsp <br/>Upload: Up to 100Gbps </td>
<td><div class="flex items-center space-x-2"><NeutralIcon/></div> Download: 10Mbsp–1Gbps <br/> Upload: 3–50Mbs</td>
<td><div class="flex items-center space-x-2"><NeutralIcon/></div> Download: 5–100Mbsp <br/>Upload: 2–5Mbs</td>
<td><div class="flex items-center space-x-2"><NeutralIcon/></div> Download: 12–25Mbsp <br/>Upload: 3Mbs </td>
<td><div class="flex items-center space-x-2"><SadIcon/></div> Download: 1.5Mbsp<br/> Upload: .5Mbs </td>
</tr>

<tr>
<th class="align-top">Shared Bandwidth</th>
<td><div class=" flex items-center space-x-2"><CheckIcon/><span>Best</span></div>No</td>
<td><div class=" flex items-center space-x-2"><SadIcon/></div>Yes</td>
<td><div class=" flex items-center space-x-2"><SadIcon/></div>Yes </td>
<td><div class=" flex items-center space-x-2"><SadIcon/></div>Yes </td>
<td><div class=" flex items-center space-x-2"><SadIcon/></div>Yes </td>

</tr>

<tr>
<th class="align-top">Data Caps</th>
<td><div class="flex items-center space-x-2"><CheckIcon/><span>Best</span></div> None</td>
<td><div class="flex items-center space-x-2"><NeutralIcon/></div>1TB</td>
<td><div class="flex items-center space-x-2"><NeutralIcon/></div>None *slower speeds after threshhold is exceeded (typically 25-50GB)</td>
<td><div class="flex items-center space-x-2"><SadIcon/></div>50-150GB</td>
<td><div class="flex items-center space-x-2"><SadIcon/></div>Not enough to cap</td>

</tr>

<tr>
<th class="align-top">Advantages</th>
<td>More secure, High Reliability, Open network with many providers </td>
<td>Incremental capacity upgrades if available</td>
<td>Available without wires to home</td>
<td>Available from anywhere</td>
<td>None</td>
</tr>

<tr>
<th class="align-top">Disadvantages</th>
<td><div class=" flex items-center space-x-2"><MoneyIcon/></div>Upfront investment (must lay fiber optic cables and install to each home and business) </td>
<td><div class=" flex items-center space-x-2"><SadIcon/></div> Not fastest option, slower upload speeds, Few choices of providers - usually a monopoly. </td>
<td><div class=" flex items-center space-x-2"><SadIcon/></div> Distance limitations, 5G mmwave have direct line of sight, subject to signal interference.  </td>
<td><div class=" flex items-center space-x-2"><SadIcon/></div> Slower speeds, more delay, subject to signal interference </td>
<td><div class=" flex items-center space-x-2"><SadIcon/></div>Slowest speeds </td>
</tr>

<tr>
<th>Availability in Uintah Highlands </th>
<td><div class="flex items-center space-x-2"><SadIcon/></div>None currently </td>
<td><div class="flex items-center space-x-2"><NeutralIcon/></div>Some availability - Comcast is currently installing some cable to homes.</td>
<td><div class="flex items-center space-x-2"><NeutralIcon/></div>Available, depends on home as to which provider you can reach. Connext, Rise Broadband, Wireless Phone Providers.</td>
<td><div class="flex items-center space-x-2"><NeutralIcon/></div>vailable, Starlink: testing scheduled late 2020</td>
<td><div class="flex items-center space-x-2"><SadIcon/></div>Available from Century Link</td>
</tr>



</tbody>

</table>

</div>