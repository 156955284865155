<script>
  export let displayName;
  export let photoURL;
  

  import { auth, googleProvider } from "../firebase.js";
  import { authState } from "rxfire/auth";

  function handleSignOut() {
    auth
      .signOut()
      .then(function() {
        console.log("Sign-out successful");
      })
      .catch(function(error) {
        console.log("Error signing out");
      });
  }
</script>

<style>.avatar{border-radius:50%;grid-area:avatar;-ms-grid-row-align:center;align-self:center;-ms-grid-column-align:center;justify-self:center}img{padding:0;margin:0}
/*# sourceMappingURL=src/components/Profile.svelte.map */</style>

<div class="flex items-center space-x-4">
  <span><img class="avatar" title={displayName} src={photoURL} width="30" alt="avatar" /></span>
  <span>{displayName}</span>
</div>


