<script>

  import { db } from "../firebase";
  import { collectionData } from "rxfire/firestore";
  import { startWith } from "rxjs/operators";
  import { Router, Route, Link, router, navigateTo } from "yrv";

  import { auth, googleProvider } from "../firebase";
  import { authState } from "rxfire/auth";



  import {
    name,
    address,
    phone,
    email,    
    city,
    state,
    zip,
    optin,
    id,
    petition
  } from "../stores.js";

let user;

  const unsubscribe = authState(auth).subscribe(u => (user = u));

 const query = db.collection("petition");
 const signees = collectionData(query,"id").pipe(startWith([]));
  
    $city = $city ? $city : "Ogden"
    $state = $state ? $state : "UT"
    $zip = $zip ? $zip : "84403"

  let formsubmitted = false;
  let validated;
  let anon;

  $: if (
    $name &&
    validatePhoneNumber($phone) &&
    $address &&
    validateEmail($email)
  ) {
    validated = true;
  } else {
    validated = false;
  }

  function validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function validatePhoneNumber(number) {
    const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return re.test(String(number).toLowerCase());
  }

let  promise;
  
function handleClick(){
  anon= false;
  promise = addName()
}

function handleAnonomous(){
  anon = true;  
  promise = addName()
}


 async function addName() {         
        
        const data = {
          sent: false,
          optin: true,
          name: $name,
          anon: anon,
          address: $address,
          city: $city,
          state: $state,
          zip: $zip,
          phone: $phone,
          email: $email,                    
          created: Date.now()        
        }
                    
        const res = await db.collection("petition").add(data);  
        $petition = res.id;              

          
    }
  


 


</script>

<style>.sendbutton{margin-top:1rem;height:auto;padding:.5rem 1rem;--text-opacity:1;color:#000;color:rgba(0,0,0,var(--text-opacity));font-size:1.5rem;font-weight:700;box-shadow:0 4px 6px -1px rgba(0,0,0,.1),0 2px 4px -1px rgba(0,0,0,.06)}.sendbutton:hover:not(:disabled){box-shadow:0 10px 15px -3px rgba(0,0,0,.1),0 4px 6px -2px rgba(0,0,0,.05);--bg-opacity:1;background-color:#4c5a65;background-color:rgba(76,90,101,var(--bg-opacity));--text-opacity:1;color:#fff;color:rgba(255,255,255,var(--text-opacity))}.sendbutton:disabled{opacity:.25}
/*# sourceMappingURL=src/pages/Petition.svelte.map */</style>

<div class="max-w-screen-lg m-auto">
  <div>
    <button
      class="block mt-8 border-0 h-auto py-2 px-4 text-black font-bold"
      on:click={() => {
        navigateTo('/');
      }}>
      ← Go Back
    </button>
  </div>
  <h2 class="p-2 text-2xl text-black">Notice of Interest</h2>
  <div class="p-2">
    <p class="text-xl">
      We, the undersigned, acknowledge
      <strong>FIBER OPTIC CABLE</strong>
      to be a superior material available now for carrying internet signals, and
      recognize the improved bandwidth available by installing it all the way
      into homes and businesses and designing the network with dedicated
      connections to reduce or eliminate neighborhood congestion. Such
      <strong>FIBER</strong>
      connections can carry a variety of services including internet, VoIP
      phone, fire and alarm signals, television channels, and unknown future
      services. Broadband services in our area over-promise and under-deliver
      service, to the extent that some have no access to upload speeds above 1
      Mbps at all, and many experience network congestion that has been unable
      to support video conferencing when working or studying from home. As such,
      we indicate our interest and desire to commit to
      <strong>FIBER</strong>
      infrastructure into our homes and a service platform giving us a choice of
      services and providers.
    </p>

    <p class="text-xl mt-2">
      We understand this proposal is to spread the cost of construction over 20
      years and that we are willing to pay up to $49 per month to pay for this,
      even though the actual cost may be less than this. We understand that
      current monthly dedicated internet pricing among 11 ISPs range from $30 to
      $37 for 250 Mbps service, from $34.95 to $54.95 for 1 Gbps service, and
      from $170 to $250 for 10 Gbps. Phone and TV channels, and any other
      service offered over the internet may be an additional cost.
    </p>

  </div>

   <div class="pt-4 mt-4 pb-4">
  {#each $signees as signee}
   <div class="inline-block mr-2 mb-2 p-1 border border-gray-light rounded-sm">{signee.anon ? 'Anononymous Resident' : signee.name}  {#if user}x{/if}</div>
   {/each}
   </div>


  {#if !$petition}
  <div class="md:grid md:grid-cols-3 md:gap-4 mt-2 border-t-2 border-b-2 py-2">

    <div class="md:col-span-1 p-2">
      <p class="mt-2 text-sm">      
        If you complete the following form, you're indicating that you wish to
        receive more detailed information on this project. By completing this
        form, you indicate that you are interested in the project as stated
        above, and that you acknowledge that this is not a contractual
        obligation to take this service, and that you reserve the right to
        review the language and commitment required before obligating yourself
        to any service.
      </p>
      <div class="mt-4">
        {#if validated}
          <p class="text-xs ">
            <span class="">✓All Fields Complete</span>
          </p>
        {:else}
          <p class="text-red text-xs">*All fields are required.</p>
        {/if}
      </div>

    </div>
    <div class="p-2 md:col-span-1">

      <div>

        <label>Your Name</label>
        <input
          class="w-full"
          type="text"
          bind:value={$name}
          placeholder="Enter your name" />
      </div>
 <div>
        <label>Street Address</label>
        <input
          class="w-full"
          type="text"
          bind:value={$address}
          placeholder="Enter your street address" />
      </div>

      <div class="flex space-x-2">
        <div class="w-1/3">
          <label>City</label>
          <input
            class="w-full"
            type="text"
            bind:value={$city}
            placeholder="City"
            disabled />
        </div>

        <div class="w-1/3">
          <label>State</label>
          <input
            class="w-full"
            type="text"
            bind:value={$state}
            placeholder="Utah"
            disabled />
        </div>

        <div class="w-1/3">
          <label>Zip</label>
          <input
            class="w-full"
            type="text"
            bind:value={$zip}
            placeholder="84403"
            disabled />
        </div>
      </div>
     

    </div>

    <div class="p-2 col-span-1">
      <div>
        <label>Phone Number</label>
        <input
          class="w-full"
          type="text"
          bind:value={$phone}
          placeholder="Enter your phone number" />
        <span class="text-xs">
          {validatePhoneNumber($phone) ? '✓ Phone' : '(Enter valid phone number)'}
        </span>
      </div>

      <div>
        <label>Email</label>
        <input
          class="w-full"
          type="text"
          bind:value={$email}
          placeholder="Enter your email address" />
        <span class="text-xs">
          {validateEmail($email) ? '✓ Email' : '(Enter valid email address)'}
        </span>
      </div>
      <div class="mt-2">
        <p class="text-xs text-black">
          If you would like to be contacted in the future about updates to this
          initiative, please check the box below.
        </p>
        <p class="mt-4">
          <label class="inline-flex items-center space-x-2">
            <input
              class="form-checkbox h-6 w-6"
              type="checkbox"
              checked
              bind:value={$optin} />
            <span class="">Join mailing list</span>
          </label>
        </p>
      </div>
    </div>
    

  </div>

  <div class="flex space-x-4">
      <button
        class="sendbutton"
        on:click={() => {
          handleClick();
        }}
        disabled={!validated}>
        <div
          class="flex items-center justify-center mx-auto space-x-4 text-center">
          <span>Add Your Name</span>
        </div>
      </button>

       <button
        class="sendbutton"
        on:click={() => {
          handleAnonomous();
        }}
        disabled={!validated}>
        <div
          class="flex items-center justify-center mx-auto space-x-4 text-center">
          <span>Add Anonomously</span>
        </div>
      </button>
    </div>


  {:else}
    {#await promise}
      Adding name to petition... 
    {:then}
     Your name has been added.
    {:catch error}
      <p class="text-red">{error.message}</p>
    {/await}
  {/if}

  

</div>
